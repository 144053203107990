import React from 'react'
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import {graphql} from "gatsby";
import {StaticImage} from "gatsby-plugin-image";
import * as styles from 'src/styles/layout/product_card.module.scss'

const title = "製品一覧";
const description = "";

const ProductsPage = ({data}) => {
  return (
    <Layout>
      <Seo title={title} description={description}/>
      <section className="container article-list-container">
        <h1>{title}</h1>

        <section className="container">
          <h2 className="top-h">スマートフォンアプリ</h2>

          <div className={styles.cardContainer}>
            <div className={styles.card}>
              <a href={"/products/akamaru"} className={styles.cardLink}>
                <StaticImage
                  src={"../../images/akamaru/feature-graphic.png"}
                  alt={"タッチであそぼ!あかまるどれかな?"}
                  className={styles.cardImage}
                />
                <p className={styles.cardDescription}>{"大人気「あかまるフレンズ」シリーズの知育アプリ"}</p>
              </a>
            </div>

            <div className={styles.card}>
              <a href={"/products/my_first_library"} className={styles.cardLink}>
                <StaticImage
                  src={"../../images/zukan/zukan_feature_graphic.png"}
                  alt={"はじめてのずかん"}
                  className={styles.cardImage}
                />
                <p className={styles.cardDescription}>{"英語も学べる！はじめてのずかん"}</p>
              </a>
            </div>
          </div>


        </section>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default ProductsPage;
